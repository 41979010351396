/**
 * Options:
 *  - Inputs:
 *      - data-parent-selector=".selector": Customizes the parent selector to which the error label will be added.
 *      By default, it will be added to the direct parent of the control.
 *
 *  - Forms or elements containing the data-request attribute:
 *      - data-request-target-elem: sets the element which will trigger its data-request operation,
 *      even if it is outside the request DOM scope. Eg: button outside a form.
 *
 */

$(window).on('ajaxErrorMessage', function(event, message){
    event.preventDefault();
});

$(document).on('jedeye.assignFormValidation', function() {
    $('button[type="submit"], a[data-request], button[data-request], button[data-request-target-elem]').click(function(event) {
        var form;
        if($(this).data('request-form') !== undefined) {
            form = $($(this).data('request-form'))[0];
        }else{
            form = $(this).closest('form')[0];
        }

        $(form).on('ajaxError', function(event, context, errorMsg, status, jqXHR){
            $('.error-label', form).remove();
            var message;
            if(typeof jqXHR.responseJSON === 'undefined') {
                message = jqXHR.responseText.match(/^\"(.*)\"\s/);
                if(message.length > 1) message = message[1];
            } else {
                message = jqXHR.responseJSON.X_OCTOBER_ERROR_FIELDS;
            }
            $('.has-error', form).removeClass('has-error');
            if( typeof message === 'object' ) {
                for(var key in message) {
                    var _key = key,
                        tmpKey = key.split('.');
                    if(tmpKey.length > 0) {
                        key = tmpKey[0];
                        for(var i = 1; i<tmpKey.length; i++) {
                            key+='[' + tmpKey[i] + ']';
                        }
                    }

                    $(this).find('input[name="' + key + '"], textarea[name="' + key + '"], select[name="' + key + '"]').parent().addClass('has-error');
                    if($(this).find('input[name="'+ key +'"], textarea[name="' + key + '"], select[name="' + key + '"]').parent().find('.error-label').length == 0) {

                        // Check if select2 dropdown plugin is rendered
                        if($(this).find('select[name="' + key + '"]').length > 0) {
                            if($(this).find('select[name="' + key + '"]').parent().find('.select2').length > 0) {
                                $(this).find('select[name="' + key + '"]').parent().find('.select2').after('' +
                                    '<div class="error-label"><label class="text-danger">' +
                                    message[_key][0] + '</label></div>');
                            }
                        }else {
                            $(this).find('input[name="' + key + '"], textarea[name="' + key + '"], select[name="' + key + '"]').each(function() {
                                var $parent = $(this).data('parent-selector') === undefined ? $(this).parent() : $(this).parents($(this).data('parent-selector') + ':first');
                                $parent.append('' +
                                    '<div class="error-label"><label class="text-danger">' +
                                    message[_key][0] + '</label></div>');
                            });
                        }
                    }

                }
            }
        });

        $(form).on('ajaxSuccess', function(event, obj, context, status) {
            $('.error-label').remove();
        });
    });
});
$(function() {
    $(document).trigger('jedeye.assignFormValidation');
});